import {
    Slider,
    Drawer,
    Input,
    ColorPicker,
    InputNumber,
    Button,
    Tooltip
} from 'element-ui'

import {
    Uploader,
    Dialog,
    Loading,
    Image as VanImage,
    Overlay 
} from 'vant'

import VueTouch from 'vue-touch'
VueTouch.config.swipe = {
    threshold: 100  //设置左右滑动的距离
}

const { initCloud } = require('@wxcloud/cloud-sdk')
const cloud = initCloud()

export default {
    async install(Vue) {
        // element-ui
        Vue.use(Slider)
        Vue.use(Drawer)
        Vue.use(Input)
        Vue.use(ColorPicker)
        Vue.use(InputNumber)
        Vue.use(Dialog)
        Vue.use(Button)
        Vue.use(Tooltip)

        // vant
        Vue.use(Uploader)
        Vue.use(Loading)
        Vue.use(VanImage)
        Vue.use(Overlay )

        Vue.use(VueTouch, { name: 'v-touch' })

        var c1 = new cloud.Cloud({
            identityless: true,
            resourceAppid: 'wxf3fe2d58d88ad9c4',
            resourceEnv: 'cloud1-7g144xneb715a224',
        })
        await c1.init()
        Vue.prototype.$cloud = c1
    }
}