import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import Global from '@/utils/global'
import VueCropper from 'vue-cropper'
import { NavBar } from 'vant';
Vue.use(NavBar)
Vue.use(VueCropper)
Vue.use(Global)

Vue.config.productionTip = false



new Vue({
  router,
  render: h => h(App),
  beforeCreate () {
    Vue.prototype.$bus = this
  },
}).$mount('#app')
