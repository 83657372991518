import Vue from 'vue'
import Router from 'vue-router'

// 默认页
const Entry = () => import('@/views/Entry')

// 主页
const Home = () => import('@/views/Home')

// 模板展示页
const Display = () => import('@/views/Display')

// 编辑页
const Edit = () => import('@/views/Edit')
const EditMusic = () => import('@/views/Edit/editMusic')

// 情书展示页
const Finished = () => import('@/views/Finished')

Vue.use(Router)

const router = new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            redirect: '/entry',
            meta: {
                templateType: "趣恋情书"
            }
        },
        {
            path: '/home/:UUID',
            name: 'home',
            component: Home,
            meta: {
                templateType: "趣恋情书"
            }
        },
        {
            path: '/display/:type/:UUID',
            name: 'display',
            component: Display,
            meta: {
                templateType: ""
            }
        },
        {
            path: '/edit/:type/:new/:UUID',
            name: 'edit',
            component: Edit,
            meta: {
                templateType: "编辑情书",
                ismusic: false,
                ispreview: false,
                keepAlive: true
            },
        },
        {
            path: '/editMusic',
            name: 'editMusic',
            component: EditMusic,
            meta: {
                templateType: "选择音乐"
            }
        },
        {
            path: '/entry',
            name: 'entry',
            component: Entry,
            meta: {
                templateType: "趣恋情书"
            }
        },
        {
            path: '/finished/:WX/:UUID',
            name: 'finished',
            component: Finished,
            meta: {
                templateType: ""
            }
        },
    ]
})
router.afterEach((to, from) => {
    if (to.meta.templateType != "") {
        Vue.prototype.$bus.$emit("changeTitle", to.meta.templateType);
        return;
    }
    if (!to.params.hasOwnProperty('type')) {
        Vue.prototype.$cloud
            .callFunction({
                name: "router",
                data: {
                    url: "getInfo",
                    params: {
                        UUID: to.params.UUID,
                    },
                },
            }).then(res => {
                let typename = "";
                switch (res.result.data.type) {
                    case 'newYear':
                        typename = "新年祝福"
                        break;
                    case 'birthday':
                        typename = "生日祝福"
                        break;
                    case 'commemoration':
                        typename = "恋爱纪念日"
                        break;
                    case 'loveLetter':
                        typename = "浪漫情书"
                        break;
                    case 'valentine':
                        typename = "情人节祝福"
                        break;
                    default:
                        break;
                }
                Vue.prototype.$bus.$emit("changeTitle", typename);
                return;
            })
    }
    let typename = "";
    switch (to.params.type) {
        case 'newYear':
            typename = "新年祝福"
            break;
        case 'birthday':
            typename = "生日祝福"
            break;
        case 'commemoration':
            typename = "恋爱纪念日"
            break;
        case 'loveLetter':
            typename = "浪漫情书"
            break;
        case 'valentine':
            typename = "情人节祝福"
            break;
        default:
            break;
    }
    Vue.prototype.$bus.$emit("changeTitle", typename);
})
export default router;