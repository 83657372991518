<template>
  <div id="app">
    <!-- <van-nav-bar
      :title="title"
      :left-text="leftText"
      :left-arrow="leftArrow"
      @click-left="onClickLeft"
      class="navbar"
    /> -->
    <div class="content">
      <!-- <van-dialog
        v-model="showTip"
        title="提示"
        show-cancel-button
        closeOnClickOverlay
        message="是否保留本次编辑内容"
        @confirm="onConfirm"
        @cancel="onCancel"
      ></van-dialog> -->
      <!-- <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view> -->
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showTip: false,
      title: "趣恋情书",
      leftArrow: true,
      leftText: "返回",
    };
  },
  mounted() {
    this.$bus.$on("changeTitle", (data) => {
      this.title = data;
    });
    this.$bus.$on("entrypage", (data) => {
      if (data == undefined) {
        this.leftArrow = false;
        this.leftText = "";
      } else return;
    });
  },
  methods: {
    // onClickLeft() {
    //   if (this.$route.name == "edit") {
    //     console.log(this.$route.meta);
    //     if (this.$route.meta.ismusic == true) {
    //       this.$route.meta.ismusic = false;
    //       this.$bus.$emit("returnToEdit", "");
    //       this.title = "编辑情书";
    //     } else if (this.$route.meta.ispreview == true) {
    //       this.$bus.$emit("returnToEdit", "");
    //       return;
    //     } else this.showTip = true;
    //     return;
    //   }
    //   this.$router.go(-1);
    // },
    // onConfirm() {
    //   this.$bus.$emit("onConfirm", "");
    // },
    // onCancel() {
    //   this.$router.go(-1);
    // },
  },
};
</script>
<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
}
::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  height: 0;
}
// .navbar {
//   position: fixed;
//   width: 100vw;
//   height: 44px;
//   top: 0;
//   left: 0;
//   z-index: 100;
//   background-color: #fff;
// }
.content {
  width: 100vw;
  // margin-top: 44px;
  height: 100vh;
  bottom: 0;
  left: 0;
}
</style>
